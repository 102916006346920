import { address_server_short, API_V2 } from '../config';
import { error } from '../utils/handlerError';
import { axiosInstance } from './common/axios.instance';

const PROTOCOLS = 'protocols';
const PROTOCOL_GROUPS = 'protocol-groups';

export async function getAllProtocolGroups({ search, sorting } = {}) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${PROTOCOL_GROUPS}`, {
    withCredentials: true,
    params: {
      search,
      sorting: sorting?.field && sorting?.type ? JSON.stringify(sorting) : undefined,
    },
  });

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getAllProtocolGroups', data);
}

export async function getProtocolGroup(id) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${id}`, {
    withCredentials: true,
  });

  if (data.success) {
    if (data.result) return data.result;
    return {};
  }
  error('getProtocolGroup', data);
}

export async function getAllProtocolsByGroupId(id) {
  const { data } = await axiosInstance(
    `${address_server_short}${API_V2}/${PROTOCOL_GROUPS}/${id}/${PROTOCOLS}`,
    {
      withCredentials: true,
    },
  );

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getAllProtocolByGroupId', data);
}

export async function createProtocolGroup(group) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${PROTOCOL_GROUPS}`, {
    withCredentials: true,
    method: 'POST',
    data: {
      name: group.name,
    },
  });

  if (!data.success) {
    error('createProtocolGroup', data);
  }

  return data;
}

export async function updateProtocolGroup(group) {
  const { data } = await axiosInstance(
    `${address_server_short}${API_V2}/${PROTOCOL_GROUPS}/${group.id}`,
    {
      withCredentials: true,
      method: 'PUT',
      data: {
        name: group.name,
      },
    },
  );

  if (!data.success) {
    error('updateProtocolGroup', data);
  }

  return data;
}

export async function deleteProtocolGroup(id) {
  const { data } = await axiosInstance(
    `${address_server_short}${API_V2}/${PROTOCOL_GROUPS}/${id}`,
    {
      withCredentials: true,
      method: 'DELETE',
    },
  );

  if (!data.success) {
    error('deleteProtocolGroup', data);
  }

  return data;
}

export async function getProtocolById(id) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${PROTOCOLS}/${id}`, {
    withCredentials: true,
  });

  if (data.success) {
    if (data.result) return data.result;
    return {};
  }
  error('getProtocolGroup', data);
}

export async function createProtocol(protocol) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${PROTOCOLS}`, {
    withCredentials: true,
    method: 'POST',
    data: {
      ...protocol,
    },
  });

  if (!data.success) {
    error('createProtocol', data);
  }

  return data;
}

export async function updateProtocol(protocol) {
  const { data } = await axiosInstance(
    `${address_server_short}${API_V2}/${PROTOCOLS}/${protocol.id}`,
    {
      withCredentials: true,
      method: 'PUT',
      data: {
        ...protocol,
      },
    },
  );

  if (!data.success) {
    error('updateProtocol', data);
  }

  return data;
}

export async function deleteProtocol(id) {
  const { data } = await axiosInstance(
    `${address_server_short}${API_V2}/${PROTOCOLS}/${id}`,
    {
      withCredentials: true,
      method: 'DELETE',
    },
  );

  if (!data.success) {
    error('deleteProtocol', data);
  }

  return data;
}