import React from 'react';
import Calculator from './Calculator/Calculator';
import classes from './WidgetFormula.module.css';

const WidgetFormula = ({ item, value, onChange, disabled = false }) => {
  return (
    <div>
      <Calculator item={item} value={value} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default WidgetFormula;
