import styles from './UpsertProtocol.module.css';

import addIcon from 'assets/svg/add-01.svg';
import attentionIcon from 'assets/svg/attention-01.svg';

import classNames from 'classnames';
import DropdownComponent from 'components/Widgets/Dropdown/DropdownComponent';
import TextField from 'components/Widgets/TextField';
import Icon from 'helpers/components/Icon';
import useAlertWidget from 'hooks/useAlertWidget';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { ProtocolOperations, protocolsStore } from 'store/protocols.store';
import { UpsertProtocolProvider, useUpsertProtocolContext } from './UpsertProtocolProvider';
import ProtocolOperation from './ProtocolOperation';

const UpsertProtocol = () => {
  return (
    <UpsertProtocolProvider>
      <UpsertProtocolComponent />
    </UpsertProtocolProvider>
  );
};

const UpsertProtocolComponent = observer(() => {
  const { setWidget } = useAlertWidget();
  const {
    groups,
    selectedProtocol,
    updateSelectedProtocol,
    upsertProtocol,
    protocolOperationMode,
    setProtocolOperationMode,
    getByIdProtocol,
    selectedProtocolId,
    removeProtocol,
    resetProtocol,
  } = protocolsStore;

  const {
    name,
    selectedGroup,
    operations,
    isFormValidate,
    editMode,
    setName,
    setSelectedGroup,
    setOperations,
    setIsFormValidate,
    setEditMode,
  } = useUpsertProtocolContext();

  const loadProtocol = async () => {
    if (selectedProtocolId) {
      const result = await getByIdProtocol(selectedProtocolId);
      updateSelectedProtocol(result);

      setName(result.name);
      setSelectedGroup(result.id_group);
      setOperations(result.operations ?? []);
      setIsFormValidate(false);
    }
  };

  useEffect(() => {
    return () => {
      resetProtocol();
    };
  }, []);

  useEffect(() => {
    if (protocolOperationMode === ProtocolOperations.Add) {
      setEditMode(true);
      setName(selectedProtocol()?.name);
      setSelectedGroup(selectedProtocol()?.id_group);
      setOperations(selectedProtocol()?.operations ?? []);
      setIsFormValidate(false);
      return;
    }
    loadProtocol();
  }, [protocolOperationMode, selectedProtocolId]);

  useEffect(() => {
    updateSelectedProtocol({
      ...selectedProtocol(),
      name: name,
      id_group: selectedGroup,
      operations: operations,
    });
  }, [name, selectedGroup, operations]);

  const moveOperationListItem = (index1, index2) => {
    if (index1 < 0 || index1 >= operations.length || index2 < 0 || index2 >= operations.length)
      return;
    setOperations((prevOperations) => {
      const newOperations = [...prevOperations];
      [newOperations[index1], newOperations[index2]] = [
        newOperations[index2],
        newOperations[index1],
      ];
      return newOperations;
    });
  };

  const isProtocolValid = () => {
    return (
      name &&
      selectedGroup &&
      operations &&
      !operations.some((operation) =>
        operation.devices.some((device) =>
          device.user_fields.some((field) => {
            if (field.required) {
              return field.value == undefined || field.value.value == '';
            }
          }),
        ),
      )
    );
  };

  const validateForm = () => {
    setIsFormValidate(true);
    const model = selectedProtocol();
    if (!isProtocolValid()) return false;
    if (model?.id)
      setWidget({
        status: 'Attention',
        text: `Данные протокола будут изменены`,
        fun: async () => {
          submitForm();
        },
      });
    else submitForm();
  };

  const submitForm = async () => {
    const result = await upsertProtocol();

    if (!result.success) {
      if (result.error_code == 2040) return alarmNameNotUnique();
      if (result.error_code) return errorCommon(result.error_code, result.error);
    } else {
      setWidget({
        status: 'Ok',
        text:
          selectedProtocol()?.id != null
            ? `Протокол успешно изменено!`
            : 'Протокол добавлен успешно!',
        fun: async () => {
          setEditMode(false);
          setProtocolOperationMode(ProtocolOperations.View);
        },
      });
    }
  };

  const handleSubmitClick = () => validateForm();

  const handleCancelClick = () => {
    setEditMode(false);
    setIsFormValidate(false);
    setProtocolOperationMode(ProtocolOperations.View);
    if (!selectedProtocolId) updateSelectedProtocol(null);
  };

  const handleEditClick = async () => {
    setEditMode(true);
    setProtocolOperationMode(ProtocolOperations.Edit);
  };

  const alarmNameNotUnique = () => {
    setWidget({
      status: 'Alarm',
      text: `Протокол с таким именем уже существует в данной группе!`,
      fun: async () => {},
    });
  }

  const errorCommon = (code, message) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}${message ? ` - ${message}` : ''}.`,
      fun: async () => {},
    });
  };

  const handleDeleteClick = async () => {
    await removeProtocol();
    resetProtocol();
  };

  const operationsMemo = useMemo(
    () =>
      operations?.map((item, index) => (
        <ProtocolOperation
          key={index}
          className={classNames(styles.operationBlock)}
          index={index}
          item={item}
          moveItem={moveOperationListItem}
        />
      )),
    [operations],
  );

  const ToolbarActions = () =>
    editMode ? (
      <>
        <button className={`button_default save_style`} onClick={() => handleSubmitClick()}>
          Сохранить
        </button>
        <button className={`button_default cancel_style`} onClick={() => handleCancelClick()}>
          Отмена
        </button>
      </>
    ) : (
      <>
        <button className={`button_default save_style`} onClick={() => handleEditClick()}>
          Редактировать
        </button>
        <div className={styles.deleteIconProtocol} onClick={() => handleDeleteClick()}></div>
      </>
    );

  return (
    <div className={styles.pageContainer}>
      <div className={styles.toolbar}>
        <ToolbarActions />
      </div>
      <div className={styles.protocolContainer}>
        {editMode && (
          <div className={styles.header}>
            <div className={styles.formField}>
              <TextField
                label={'Наименование'}
                field={name}
                onChange={setName}
                required
                isFormValidate={isFormValidate}
              ></TextField>
            </div>
            <div className={styles.formField}>
              <div className={styles.formFieldContainer}>
                <div className={styles.label}>Группа</div>
                <DropdownComponent
                  items={groups}
                  keyItem={'id'}
                  valueItem={'name'}
                  selectedKey={selectedGroup}
                  onChange={(value) => setSelectedGroup(value)}
                />
              </div>
              {!selectedGroup && isFormValidate && (
                <div className={styles.dataErrorBlock}>
                  <img src={attentionIcon} alt={'attention'} />
                  <div className={styles.dataError}>Поле обязательно для заполнения</div>
                </div>
              )}
            </div>
          </div>
        )}
        {operationsMemo}
        {editMode && (
          <div className={classNames(styles.operationBlock, styles.addOperationBlock)}>
            <button
              className={styles.addOperationButton}
              onClick={() => {
                setOperations([
                  ...operations,
                  {
                    text: '',
                    time: {
                      value: '00:00',
                      setting: { date: '00:00' },
                    },
                    devices: [],
                  },
                ]);
              }}
            >
              <div className={classNames(styles.addIconContainer)}>
                <Icon
                  className={classNames(styles.addIcon)}
                  color={'#01b454'}
                  src={addIcon}
                  width={'14px'}
                  height={'14px'}
                />
              </div>
              <span>Добавить действие</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default UpsertProtocol;
