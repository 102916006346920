import React, { useEffect, useState } from 'react';
import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import { COLORS, ymaps_apikey as apikey } from '../../config';
import TextareaAutosize from 'react-textarea-autosize';
import classes from './WidgetLocation.module.css';
import str from '../../assets/svg/arrow-01.svg';
import Icon from '../../helpers/components/Icon';

// default
const geo = [55.753994, 37.622093];
const placeHolder = 'Локация ещё не присвоена';

const MapComponent = ({ item, value, handleAddressUpdate, changeValue }) => {
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const [center, setCenter] = useState(
    value ? [value?.latitude ?? geo[0], value?.longitude ?? geo[1]] : geo,
  ); // default geo moscow
  const [zoom, setZoom] = useState(10);

  const updateValueAddress = async (center) => {
    const { ymaps } = await window;
    const response = await ymaps.geocode(center);
    const address = response.geoObjects.get(0).getAddressLine();
    handleAddressUpdate(address);
    changeValue({ address, center });
  };

  useEffect(() => {
    setCenter(value?.latitude ? [value?.latitude, value?.longitude] : geo);
    handleAddressUpdate(value?.address);
    handleAddressUpdate(value?.address);
  }, [value]);

  useEffect(() => {
    if (!value) {
      const val = item;
      changeValue({ address: val.address, center: [val.latitude, val.longitude] });
    }
  }, []);

  useEffect(() => {
    if (!value) {
      const { address, latitude, longitude } = item.setting;
      changeValue({ address, center: [latitude, longitude] });
    }
  }, [item]);

  useEffect(() => {
    setZoom(zoom);
    setCenter(center);
  }, [isMapFullscreen]);

  const handleDragEnd = (ev) => updateValueAddress(ev.get('target').geometry.getCoordinates());
  const mapFullscreenUpdate = () => setIsMapFullscreen((prev) => !prev);

  return (
    <YMaps query={{ apikey }}>
      <Map
        defaultState={{ center: geo, zoom }}
        state={center ? { center, zoom } : null}
        onClick={(e) => {
          const val = e.get('coords');
          setCenter(val);
          updateValueAddress(val);
        }}
      >
        <Placemark
          geometry={center}
          options={{
            draggable: true,
            preset: 'islands#dotIcon',
            iconColor: COLORS.color_brand,
          }}
          onDragEnd={handleDragEnd}
        />
        <SearchControl
          options={{
            float: 'right',
            preset: 'islands#dotIcon',
            iconColor: COLORS.color_brand,
          }}
        />
        <ZoomControl options={{ float: 'left' }} />
        <FullscreenControl state={isMapFullscreen} onClick={mapFullscreenUpdate} />
      </Map>
    </YMaps>
  );
};

const WidgetLocation = ({ item, value, onChange, disabled = false }) => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [address, setAddress] = useState(value?.setting?.address || '');

  const handlerClickInput = () => {
    setIsMapOpen((prev) => !prev);
  };

  const changeValue = ({ address, center }) => {
    const latitude = center[0];
    const longitude = center[1];
    onChange({
      value: address,
      setting: {
        address,
        latitude,
        longitude,
      },
    });
  };

  return (
    <div className={classes.location_group}>
      <div className={classes.input_group}>
        <TextareaAutosize
          maxRows={10}
          placeholder={placeHolder}
          className={classes.input}
          onClick={handlerClickInput}
          value={address}
          readOnly={true}
        />
        <Icon
          src={str}
          color={COLORS.text_secondary}
          width={12}
          height={7}
          className={`${classes.arrow} ${isMapOpen ? classes.flippedArrowIcon : ''}`}
          onClick={handlerClickInput}
        />
      </div>
      {isMapOpen && !disabled ? (
        <MapComponent
          item={item}
          value={value.setting}
          changeValue={changeValue}
          handleAddressUpdate={(val) => setAddress(val)}
        />
      ) : null}
    </div>
  );
};

export default WidgetLocation;
