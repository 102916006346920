import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styles from './userFields.module.css';
import confirmSVG from 'assets/svg/button_on-01.svg';
import { COLORS } from '../../config';
import Icon from '../../helpers/components/Icon';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useCallback } from 'react';
import { getListTypeFields } from '../../services/list.services';
import searchSVG from 'assets/svg/serch-01.svg';
import { modeContext } from '../Contexts/contexts';

import WorkRightSelectPage from '../WorkRight/WorkRightCommon/WorkRightSelectPage/workRightSelectPage';
import { observer } from 'mobx-react-lite';
import AddFieldModal from './AddFieldModal/AddFieldModal';
import arrow from 'assets/svg/arrow-01.svg';
import add from 'assets/svg/add-01.svg';
import close from 'assets/svg/close-01.svg';
import { re } from 'mathjs';
import TableRow from './TableRow';

import arrowIcon from 'assets/svg/arrow-01.svg';
import classNames from 'classnames';
import Pagination from './Pagination';
import ActionToolbar from './ActionToolBar';
import Sort from './Sort';
import TableHeader from './TableHeader';
import { allowedFieldTypes } from 'consts/userField.consts';

const UserFieldsContext = createContext(null);

export const useUserFieldsContext = () => {
  const context = useContext(UserFieldsContext);
  if (!context) {
    throw new Error('useUserFieldsContext must be used within a UserFieldsProvider');
  }
  return context;
};

export const UserFields = forwardRef(({ mode, loadApi, category = undefined }, ref) => {
  const mainMode = React.useContext(modeContext);
  const { setWidget } = mainMode.widget;

  const [types, setTypes] = React.useState([]);

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortedField, setSortedField] = useState({ type: '', field: '' });
  const [search, setSearch] = useState('');
  const [totalItemsLength, setTotalItemsLength] = useState(0);

  const [commonUserFields, setCommonUserFields] = useState([]);
  const [selectedCommonFields, setSelectedCommonFields] = useState([]);
  const [localUserFields, setLocalUserFields] = useState([]);
  const [selectedLocalFields, setSelectedLocalFields] = useState([]);
  const [isAddFieldSuccess, setIsAddFieldSuccess] = useState(false);

  const [columns, setColumns] = useState([
    {
      field: 'name',
      name: 'Название',
      type: 'string',
      sort: true,
    },
    {
      field: 'type',
      name: 'Тип',
      type: 'string',
      sort: false,
    },
    {
      field: 'required',
      name: 'Обязательное',
      type: 'switch',
      sort: true,
    },
  ]);

  useImperativeHandle(ref, () => ({
    getSelectedUserfields: () => localUserFields,
  }));

  const loadAllTypes = async () => {
    let res = await getListTypeFields();
    res = allowedFieldTypes[category]
      ? res.filter((t) => allowedFieldTypes[category].includes(t.type_name))
      : res;
    setTypes(res);
  };

  const loadAllUserFields = async () => {
    let res = await loadApi({
      page: page,
      sorting: sortedField,
      search: search ? search : undefined,
      category: category,
    });

    setCommonUserFields(
      res?.data?.map((val) => {
        return { ...val, isCheck: false, isMoreInfo: false, deletable: true };
      }),
    );
    setPage(res?.pagination.page);
    setTotalItemsLength(res?.pagination.items_count);
    setItemsPerPage(res?.pagination.limit);
  };

  React.useEffect(() => {
    loadAllTypes();
  }, []);

  React.useEffect(() => {
    loadAllUserFields();
  }, [page, sortedField, search, isAddFieldSuccess]);

  const moveToLocal = () => {
    setLocalUserFields([
      ...localUserFields,
      ...selectedCommonFields.filter(
        (selected) => !localUserFields.some((existing) => existing.id == selected.id),
      ),
    ]);
    setSelectedCommonFields([]);
  };

  const moveToCommon = () => {
    setLocalUserFields([
      ...localUserFields.filter(
        (local) => !selectedLocalFields.some((selected) => selected.id == local.id),
      ),
    ]);
    setSelectedLocalFields([]);
  };

  const dropFromLocalField = () => {
    setWidget({
      status: 'Sure',
      text: `Вы действительно хотите удалить ${localUserFields?.filter((val) => val.isCheck).length == 1 ? 'выбранное поле' : 'выбранные поля'} ?`,
      fun: async () => {},
    });
  };

  const handleAddToCommon = (item, value) => {
    if (value) {
      !selectedCommonFields.some((val) => val.id === item.id) &&
        setSelectedCommonFields([...selectedCommonFields, item]);
    } else setSelectedCommonFields(selectedCommonFields.filter((it) => it.id !== item.id));
  };

  const handleAddToLocal = (item, value) => {
    if (value) {
      !selectedLocalFields.some((val) => val.id === item.id) &&
        setSelectedLocalFields([...selectedLocalFields, item]);
    } else setSelectedLocalFields(selectedLocalFields.filter((it) => it.id !== item.id));
  };

  const dragAndDroptListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = localUserFields[dragIndex];
      const hoverItem = localUserFields[hoverIndex];
      // Swap places of dragItem and hoverItem in the pets array
      const updatedData = [...localUserFields];
      updatedData[dragIndex] = hoverItem;
      updatedData[hoverIndex] = dragItem;
      setLocalUserFields(updatedData);
    },
    [localUserFields],
  );

  const handleClickChangePage = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  return (
    <div className={styles.leftTabContainer}>
      <UserFieldsContext.Provider
        value={{
          loadAllUserFields,
          sortedField,
          setSortedField,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <div className={styles.containerHeader}>
            <span className={styles.infoBlock}>Добавлено: {localUserFields.length}</span>
            <div className={styles.infoBlock}>
              <ActionToolbar
                mode={mode}
                types={types}
                search={search}
                onSearch={setSearch}
                category={category}
                isAddFieldSuccess={setIsAddFieldSuccess}
              ></ActionToolbar>
              <span>Найдено: {totalItemsLength}</span>
            </div>
          </div>
          <div className={styles.tables}>
            <div className={styles.tableLeftContainer}>
              <table>
                <TableHeader columns={columns} isSortable={false} />
                <tbody className={styles.rows}>
                  {!localUserFields.length && (
                    <tr>
                      <td colSpan={5} className={styles.noData}>
                        Выберите поле для заполнения
                      </td>
                    </tr>
                  )}
                  {localUserFields?.map((item, index) => (
                    <TableRow
                      key={index}
                      index={index}
                      row={item}
                      isSelected={selectedLocalFields.some((val) => val.id === item.id)}
                      moveItem={dragAndDroptListItem}
                      onSelect={(value) => handleAddToLocal(item, value)}
                    ></TableRow>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.moveButton}
                onClick={moveToLocal}
                disabled={selectedCommonFields.length == 0}
              >
                <Icon
                  className={styles.leftArrow}
                  color={'white'}
                  src={'/svg/arrow-01.svg'}
                  width={'20px'}
                  height={'20px'}
                />
              </button>
              <button
                className={styles.moveButton}
                onClick={moveToCommon}
                disabled={selectedLocalFields.length == 0}
              >
                <Icon
                  className={styles.rightArrow}
                  color={'white'}
                  src={'/svg/arrow-01.svg'}
                  width={'20px'}
                  height={'20px'}
                />
              </button>
            </div>
            <div className={styles.tableRightContainer}>
              <table>
                <TableHeader columns={columns} isSortable={true} />
                <tbody className={styles.rows}>
                  {commonUserFields?.map((item, index) => (
                    <TableRow
                      key={index}
                      index={index}
                      row={item}
                      isSelected={selectedCommonFields.some((val) => val.id === item.id)}
                      onSelect={(value) => handleAddToCommon(item, value)}
                    ></TableRow>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <Pagination
              totalItemsLength={totalItemsLength}
              itemsPerPageCount={itemsPerPage}
              currentPage={page}
              setCurrentPage={setPage}
            />
          </div>
        </DndProvider>
      </UserFieldsContext.Provider>
    </div>
  );
});
