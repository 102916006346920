// Autocomplete.js
import React, { useEffect, useRef, useState } from 'react';
import './Autocomplete.css';
import classNames from 'classnames';

const Autocomplete = ({
  items,
  keyItem = 'id',
  valueItem = 'name',
  onChange,
  onSelect,
  selected,
  preSelectedItems = undefined,
  preSelectedTitle = 'Используется'
}) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [inputValue, setInputValue] = useState('');
  const [visibleOptions, setVisibleOptions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (selected) {
      setInputValue(selected[valueItem] ?? '');
    }
  }, [selected]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setFilteredItems(items);

    event.stopPropagation();
    if (event.target.value.length < 3) {
      setFilteredItems([]);
      return;
    }
    onChange(inputValue);
  };

  const handleSelect = (value) => {
    setInputValue(value[valueItem]);
    setVisibleOptions(false);
    // setFilteredItems([]);
    onSelect(value[keyItem]);
  };

  const toggleVisibility = () => {
    setVisibleOptions((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisibleOptions(false);
    } else setVisibleOptions(true);
  };

  return (
    <div ref={ref} className="autocomplete-container">
      <input
        className="autocomplete-input"
        type="text"
        value={inputValue}
        onChange={handleChange}
        onClick={() => setVisibleOptions(true)}
        placeholder="Введите название..."
      />
      {visibleOptions && <ul className="autocomplete-suggestions" style={{ border: visibleOptions && filteredItems.length ? '1px solid #ccc' : 'none' }}>
        {preSelectedItems && (
          <>
            <li className="suggestion-title">{preSelectedTitle}</li>
            {preSelectedItems?.map((item, index) => (<li key={index} className="autocomplete-suggestion" onClick={() => handleSelect(item)}>
              {item[valueItem]}
            </li>))}
          </>
        )}
        <li className="suggestion-title">Результаты поиска</li>
        {filteredItems?.map((item, index) => (
          <li key={index} className="autocomplete-suggestion" onClick={() => handleSelect(item)}>
            {item[valueItem]}
          </li>
        ))}
      </ul>}
    </div>
  );
};

export default Autocomplete;
