import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './UpsertGroupModal.module.css';
import { modeContext } from '../../../Contexts/contexts';
import Icon from 'helpers/components/Icon';
import close from 'assets/svg/close-01.svg';
import TextField from 'components/Widgets/TextField';
import { protocolsStore } from 'store/protocols.store';

const UpsertGroupModal = ({ setModalOpened }) => {
  const mainMode = React.useContext(modeContext);
  const { setWidget } = mainMode.widget;

  const { setOperationMode, selectedGroup, updateSelectedGroup, upsertGroup } = protocolsStore;

  const [group, setGroup] = React.useState(null);
  const [isFormValidate, setIsFormValidate] = React.useState(false);

  const [name, setName] = React.useState('');

  useEffect(() => {
    setGroup(selectedGroup());
    setIsFormValidate(false);
  }, []);

  useEffect(() => {
    setName(group?.name ?? '');
  }, [group]);

  useEffect(() => {
    updateSelectedGroup({
      id: group?.id,
      name: name,
    });
  }, [name]);

  const submitClick = async () => {
    setIsFormValidate(true);
    const group = selectedGroup();

    if (!group?.name?.trim()) return;

    if (group?.id) {
      setWidget({
        status: 'Attention',
        text: `Группа будет изменена`,
        fun: async () => {
          await performSubmit();
        },
      });
    } else {
      await performSubmit();
    }
  };

  const cancelClick = () => {
    closeModal();
  };

  const performSubmit = async () => {
    const result = await upsertGroup();
    if (result.success) {
      setModalOpened(false);
      setWidget({
        status: 'Ok',
        text:
          selectedGroup()?.id != null ? `Группа успешно изменена!` : `Группа добавлено успешно!`,
        fun: async () => {
          closeModal();
        },
      });
    } else {
      if (result.error_code != 2035) {
        setWidget({
          status: 'Error',
          text: `Код № ${result.error_code}`,
          fun: () => {},
        });
      } else {
        setWidget({
          status: 'Alarm',
          text: 'Группа с таким именем уже существет!',
          fun: async () => {},
        });
      }
    }
  };

  const closeModal = () => {
    updateSelectedGroup(null);
    setOperationMode(null);
    setModalOpened(false);
  };

  const modal = (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            {group?.id == null ? 'Добавить' : 'Редактировать'} группу протоколов
          </div>
          <div className={styles.closeButton} onClick={() => closeModal()}>
            <Icon color={'#1d6030'} width={'15px'} height={'15px'} src={close} />
          </div>
        </div>
        <div className={styles.modalBody}>
          <TextField
            className={styles.formField}
            label={'Наименование'}
            field={name}
            onChange={setName}
            required
            isFormValidate={isFormValidate}
          ></TextField>
        </div>
        <div className={styles.footer}>
          <button className={`button_default save_style`} onClick={submitClick}>
            {group?.id == null ? 'Добавить' : 'Редактировать'}
          </button>
          <button className={`button_default cancel_style`} onClick={cancelClick}>
            Отмена
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default UpsertGroupModal;
