import React, { useContext, useEffect, useState } from 'react';
import classes from './work.module.css';
import WorkRight from '../WorkRight/WorkRightCommon/WorkRight/workRight';
import WorkLeft from '../WorkLeft/WorkLeft/workLeft';
import { modeContext, wrapContext } from '../Contexts/contexts';
import { StorageStore } from '../../store/StorageStore';
import { observer } from 'mobx-react-lite';

const Work = observer(({ fullMenu }) => {
  const [sizeX, setSizeX] = useState(400);
  const [canResize, setCanResize] = useState(false);
  const [hiddenHeader, setHiddenHeader] = useState({});
  const { wrap } = useContext(wrapContext);
  const { mode } = useContext(modeContext);

  const storageStore = useContext(StorageStore);

  useEffect(() => {
    if (wrap) {
      setSizeX(0);
      setHiddenHeader({ position: 'absolute', paddingTop: '20px', height: 'calc(100% - 20px)' });
    } else {
      setSizeX(420);
      setHiddenHeader({});
    }
  }, [wrap]);

  if (
    mode.path === 'Хранилище' ||
    mode.path === 'Образцы' ||
    mode.path === 'Архив' ||
    mode.path === 'Образцы в хранилище'
  ) {
    storageStore.clearSelectedSample();
  }

  return (
    <div
      className={classes.Work}
      style={hiddenHeader}
      onMouseMove={(event) => {
        if (canResize) {
          let size = event.clientX - 220 * fullMenu - 70 * (1 - fullMenu);
          if (size > 350) setSizeX(event.clientX - 220 * fullMenu - 70 * (1 - fullMenu));
        }
      }}
      onMouseUp={(event) => {
        setCanResize(false);
      }}
    >
      {(mode.path === 'Пользователи' ||
        mode.path === 'Отчеты' ||
        mode.path === 'Хранилище' ||
        mode.path === 'Справочники') && (
          <>
            <WorkLeft mode={mode} sizeX={sizeX} />
            {sizeX !== 0 && (
              <div
                className={classes.Work_resize}
                onMouseDown={(event) => {
                  setCanResize(true);
                }}
              ></div>
            )}
            <WorkRight mode={mode} sizeX={sizeX} />
          </>
        )}
      {(mode.path === 'Профиль пользователя' ||
        mode.path === 'Образцы' ||
        mode.path === 'Архив' ||
        mode.path === 'Образцы в хранилище' ||
        mode.path === 'Импорт' ||
        mode.path === 'Настройки' ||
        mode.path === 'Настройки системы' ||
        mode.path === 'Карточка образца' ||
        mode.path === 'Общие настройки' ||
        mode.path === 'Типы источников образцов' ||
        mode.path === 'Новый тип источника образца' ||
        mode.path === 'Редактировать тип источника образца' ||
        mode.path === 'Источники образцов' ||
        mode.path === 'Оборудование' ||
        mode.path === 'Создать оборудование' ||
        mode.path === 'Редактировать оборудование' ||
        mode.path === 'Протоколы') && <WorkRight mode={mode} sizeX={0} />}
      {canResize && <div className={classes.Work_resize_block}></div>}
    </div>
  );
});

export default Work;
