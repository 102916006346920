import styles from './ListNavigation.module.css';
import { useListDrawerContext } from '../ListDrawerProvider';
import ActionButton from 'ui/ActionButton';
import editIcon from 'assets/svg/edit-01.svg';
import deleteIcon from 'assets/svg/delete-01.svg';
import classNames from 'classnames';
import Icon from 'helpers/components/Icon';
import arrowIcon from 'assets/svg/arrow-01.svg';
import { useEffect, useMemo, useState } from 'react';
import { COLORS } from 'config.js';
import ListItem from './ListItem';
import { observer } from 'mobx-react-lite';
import React from 'react';

const ListGroupItem = React.memo(observer(({ group }) => {
  const {
    loadGroupApi,
    resetGroup,
    editGroupCallback,
    deleteGroupCallback,
    selectedKey,
    expandedGroups,
    setExpandedGroups,
  } = useListDrawerContext();
  const [isExpandGroup, setIsExpandGroup] = useState(false);
  
  useEffect(() => {
    setIsExpandGroup(expandedGroups.includes(group?.id));
  }, [group?.id]);

  const handleExpandGroup = async (id) => {
    if (loadGroupApi) {
      !isExpandGroup ? await loadGroupApi(group[selectedKey]) : resetGroup(group[selectedKey]);
    }
    setExpandedGroups(prev => 
      prev.includes(id) ? prev.filter(groupId => groupId !== id) : [...prev, id]
    );
    setIsExpandGroup(!expandedGroups.includes(id));
  };

  const noContent = () => (
    <tr>
      <td className={classNames(styles.noDataRow, styles.item, styles.groupItem)} colSpan={2}>
        Нет данных
      </td>
    </tr>
  );
  
  const nestedItemsMemo = useMemo(
    () =>
      group.items?.length ? group.items?.map((item, index) => (
        <ListItem cellClassName={styles.nestedItem} key={index} item={item} />
      )) : noContent(),
    [group.items],
  );

  return (
    <>
      <tr className={classNames(styles.rowItem)}>
        <td className={classNames(styles.item, styles.groupItem)} onClick={() => {handleExpandGroup(group.id)}}>
          <div className={styles.itemName}>
            <button className={classNames(styles.expandButton)}>
              <Icon
                className={classNames(styles.expandIcon, { [styles.expandedIcon]: isExpandGroup })}
                color={COLORS.text_primary_brand}
                src={arrowIcon}
                width={'12px'}
                height={'12px'} />
            </button>
            {group.name}
          </div>
        </td>
        <td className={styles.actionButtons}>
          {group.is_editable && (
            <ActionButton
              icon={editIcon}
              styleConfig={{
                container: styles.actionButton,
                iconContainer: styles.actionButtonIconContainer,
                tooltipText: 'Редактировать'
              }}
              onClick={() => { editGroupCallback(group) }}
            />
          )}
          {group.is_deletable && (
            <ActionButton
              icon={deleteIcon}
              styleConfig={{
                container: styles.actionButton,
                iconContainer: styles.actionButtonIconContainer,
                tooltipText: 'Удалить'
              }}
              onClick={() => { deleteGroupCallback(group) }}
            />
          )}
        </td>
      </tr>
      {isExpandGroup && nestedItemsMemo}
    </>
  );
}));

export default ListGroupItem;
