import styles from './DrawerContent.module.css';
import { useEffect, useRef } from 'react';
import { useListDrawerContext } from '../ListDrawerProvider';
import classNames from 'classnames';

const DrawerContent = ({ children }) => {
  const {
  } = useListDrawerContext();

  const columnsSettingsRef = useRef(null);


  const handleClickOutside = (event) => {
    if (columnsSettingsRef.current && !columnsSettingsRef.current.contains(event.target)) {
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const noContent = () => (
    <div className={styles.noContent}>
      <span>Ничего не выбрано</span>
    </div>
  );

  return (
    <div className={styles.container}>
      {children || noContent()}
    </div>
  );
};

export default DrawerContent;
