import styles from './ProtocolOperation.module.css';

import addIcon from 'assets/svg/add-01.svg';
import arrowIcon from 'assets/svg/arrow-01.svg';
import attentionIcon from 'assets/svg/attention-01.svg';
import closeIcon from 'assets/svg/close-01.svg';
import deleteIcon from 'assets/svg/delete-01.svg';

import classNames from 'classnames';
import WidgetDateTimePicker from 'components/Widgets/WidgetDateTimePicker';
import dayjs from 'dayjs';
import Icon from 'helpers/components/Icon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DeviceItem from './DeviceItem';
import { protocolsStore } from 'store/protocols.store';
import { useUpsertProtocolContext } from '../UpsertProtocolProvider';

const ProtocolOperation = ({ className = undefined, index, item, moveItem = undefined }) => {
  const { isFormValidate, setOperations, editMode } = useUpsertProtocolContext();
  const { updateOperation } = protocolsStore;
  const [inputText, setInputText] = useState(() => item.text);
  const [inputTime, setInputTime] = useState(() => item.time);
  const [devices, setDevices] = useState(() => item.devices ?? []);

  useEffect(() => {
    setInputText(item.text);
    setInputTime(item.time);
    setDevices(item.devices);
  }, [item]);

  useEffect(() => {
    updateOperation(index, {
      ...item,
      text: inputText,
      time: inputTime,
      devices: devices,
    });
    setOperations((prevOperations) =>
      prevOperations.map((op, i) =>
        i == index
          ? {
              ...item,
              text: inputText,
              time: inputTime,
              devices: devices,
            }
          : op,
      ),
    );
  }, [inputText, inputTime, devices]);

  const updateDevice = useCallback((deviceIndex, newDevice) => {
    setDevices((prevDevices) => {
      const updatedDevices = [...prevDevices];
      updatedDevices[deviceIndex] = newDevice;
      return updatedDevices;
    });
  }, []);

  const deleteDevice = useCallback((deviceIndex) => {
    setDevices((prevDevices) => prevDevices.filter((_, i) => i !== deviceIndex));
  }, []);

  const createDevice = useCallback(() => setDevices((prevDevices) => [...prevDevices, {}]), []);

  const devicesMemo = useMemo(
    () =>
      devices.map((device, deviceIndex) => (
        <div
          key={device?.id ?? `device-${deviceIndex}`}
          className={styles.deviceOperationContainer}
        >
          <DeviceItem index={deviceIndex} item={device} updateDevice={updateDevice} />
          {editMode && (
            <div
              className={classNames(styles.removeDeviceIconContainer)}
              onClick={() => deleteDevice(deviceIndex)}
            >
              <Icon src={deleteIcon} color={'#01b454'} width={'20px'} height={'20px'} />
            </div>
          )}
        </div>
      )),
    [devices, editMode],
  );

  return (
    <div className={classNames(className)}>
      <div className={styles.itemContainer}>
        <div className={styles.indexContainer}>
          <div className={styles.index}>{index + 1}.</div>
          {editMode && (
            <div className={styles.arrowIconContainer} onClick={() => moveItem(index - 1, index)}>
              <Icon
                className={classNames(styles.arrowIcon, styles.rotatedArrow)}
                color={'#01b454'}
                src={arrowIcon}
              />
            </div>
          )}
          {editMode && (
            <div className={styles.arrowIconContainer} onClick={() => moveItem(index + 1, index)}>
              <Icon className={styles.arrowIcon} color={'#01b454'} src={arrowIcon} />
            </div>
          )}
        </div>
        <div>
          <div className={styles.operationContainer}>
            <div className={styles.operationTextContainer}>
              <textarea
                className={styles.textarea}
                placeholder={'Введите описание'}
                value={inputText}
                readOnly={!editMode}
                onChange={(event) => setInputText(event.target.value)}
              />
              {!inputText?.trim() && isFormValidate && (
                <div className={styles.dataErrorBlock}>
                  <img src={attentionIcon} alt={'attention'} />
                  <div className={styles.dataError}>Поле обязательно для заполнения</div>
                </div>
              )}
            </div>
            <div className={styles.timer}>
              <div>Время выполнения</div>
              {editMode && (
                <WidgetDateTimePicker
                  selected={inputTime.value}
                  onChange={(val) => {
                    setInputTime({
                      value: dayjs(val).format('HH:mm') ?? ' ',
                      setting: { date: dayjs(val).format('HH:mm') },
                    });
                  }}
                  isTime={true}
                />
              )}
              {!editMode && <span className={styles.timerValue}>{inputTime.value}</span>}
            </div>
          </div>
          <div className={styles.title}>Оборудование</div>
          {devicesMemo}
          {editMode && (
            <div className={styles.addDevice}>
              <div className={styles.addDeviceButtonContainer} onClick={createDevice}>
                <div className={styles.addButtonContainer}>
                  <Icon className={styles.addButton} color={'#fff'} src={addIcon} />
                </div>
                <span>Добавить оборудование</span>
              </div>
            </div>
          )}
        </div>
        {editMode && (
          <div
            className={classNames(styles.deleteIconContainer)}
            onClick={() =>
              setOperations((prevOperations) => prevOperations.filter((_, i) => i !== index))
            }
          >
            <Icon src={closeIcon} color={'#01b454'} width={'14px'} height={'14px'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProtocolOperation;
