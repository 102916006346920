export const stringSorting = (array, field, desc = false) => {
  return array?.sort((a, b) => {
    const valueA = a[field]?.toLowerCase();
    const valueB = b[field]?.toLowerCase();
    if (desc ? valueA > valueB : valueA < valueB) return -1;
    if (desc ? valueA < valueB : valueA > valueB) return 1;
    return 0;
  });
};

export const timeToMilliseconds = (timeStr) => {
  const [hours, minutes] = timeStr.split(':').map(Number); // Разбиваем строку на часы и минуты
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000; // Переводим в миллисекунды
};

export const millisecondsToTime = (ms) => {
  const date = new Date(ms);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};
