import styles from './DeviceItem.module.css';

import attentionIcon from 'assets/svg/attention-01.svg';
import arrowIcon from 'assets/svg/arrow-01.svg';

import Autocomplete from 'components/Widgets/Autocomplete/Autocomplete';
import WorkRightAddMenuItem from 'components/WorkRight/WorkRightManualDir/WorkRightAddMenu/workRightAddMenuItem';
import { address_server_short, COLORS, PUBLIC_ASSETS } from 'config.js';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getAllDeviceModels } from 'services/device.services';
import Icon from 'helpers/components/Icon';
import classNames from 'classnames';
import { useUpsertProtocolContext } from '../../UpsertProtocolProvider';

const DeviceItem = ({ index, item, updateDevice }) => {
  const { isFormValidate, operations, editMode } = useUpsertProtocolContext();
  const [selected, setSelected] = useState(() => item);

  const [devicesOptions, setDevicesOptions] = useState([]);
  const [userFieldsState, setNewUserFieldsState] = useState({ id: 0, fields: {} });
  const [isExpandedRow, setIsExpandedRow] = useState(false);

  useEffect(() => {
    setSelected(item);
    setIsExpandedRow(false);
  }, [item]);

  const handleSearchDevicesOptions = async (searchStr) => {
    const result = await getAllDeviceModels({ search: searchStr });
    setDevicesOptions(result.data);
  };

  const handleUpdateSelectedDevice = (value) => {
    const target =
      devicesOptions.find((device) => device.id === value) ||
      preSelectedItems.find((device) => device.id === value);

    target.user_fields.map((field) => {
      field.value = { value: '', setting: [] };
    });
    setSelected(target);
    updateDevice(index, target);
  };

  const getUniqueDevicesByUserFields = () => {
    return _.uniqWith(
      operations.flatMap((operation) => operation.devices).filter((device) => !_.isEmpty(device)), // Убираем пустые устройства
      (a, b) => _.isEqual(a.user_fields, b.user_fields),
    );
  };

  const handleOnUpdateUserFields = (newState) => {
    setNewUserFieldsState(newState);
    const updatedSelected = {
      ...selected,
      user_fields: configureUserFields(),
    };

    if (!_.isEqual(updatedSelected.user_fields, selected.user_fields)) {
      updateDevice(index, updatedSelected);
    }
  };

  useEffect(() => {
    if (Array.isArray(selected?.user_fields)) {
      const userFields = { ...userFieldsState };

      selected.user_fields.map((val) => {
        if (val.type.type_name == 'file')
          userFields.fields[val.id] = {
            value: [],
            setting: JSON.parse(val.setting),
            files: val.value,
          };
        else if (val.type.type_name == 'image')
          userFields.fields[val.id] = {
            value: [],
            setting: JSON.parse(val.setting),
            files: val.value,
          };
        else {
          try {
            userFields.fields[val.id] = JSON.parse(val.value);
          } catch {
            userFields.fields[val.id] = val.value;
          }
        }
      });
      setNewUserFieldsState(userFields);
    }
  }, [selected]);

  const preSelectedItems = useMemo(() => {
    const res = getUniqueDevicesByUserFields();
    return res;
  }, [operations]);

  const configureUserFields = () =>
    [...selected.user_fields].map((field) => {
      const changedValue = userFieldsState.fields[field.id];
      if (changedValue && changedValue.files)
        return {
          ...field,
          value: changedValue.files.filter((val) => typeof val === 'string'),
        };

      if (changedValue)
        return {
          ...field,
          value: changedValue,
        };

      return { ...field };
    });

  const handleExpandRow = () => setIsExpandedRow((prevIsExpanded) => !prevIsExpanded);

  return (
    <div key={index} className={styles.deviceItemContainer}>
      {editMode && (
        <Autocomplete
          items={devicesOptions}
          // preSelectedItems={preSelectedItems}
          selected={selected}
          keyItem={'id'}
          valueItem={'name'}
          onChange={(e) => handleSearchDevicesOptions(e)}
          onSelect={handleUpdateSelectedDevice}
        />
      )}
      {!editMode && (
        <div className={styles.devicePreview}>
          <button className={styles.expandButton} onClick={handleExpandRow}>
            <Icon
              className={classNames({ [styles.hiddenRow]: !isExpandedRow })}
              color={COLORS.color_brand}
              src={arrowIcon}
              width={'14px'}
              height={'14px'}
            />
          </button>
          <div>{item.name}</div>
        </div>
      )}
      {(editMode || isExpandedRow) && (
        <div className={styles.deviceInfoContainer}>
          {!editMode && (
            <div className={styles.iconsContainer}>
              <div className={styles.itemIcon}>
                <div className={styles.containerImg}>
                  <img
                    src={address_server_short + PUBLIC_ASSETS + item.image}
                    className={styles.itemImg}
                    alt="N/A"
                  />
                </div>
              </div>
            </div>
          )}
          <div className={styles.userFieldsEditContainer}>
            {selected?.user_fields?.map((val, userFieldIndex) => {
              const handlerInputData = (data) => {
                const userFields = { ...userFieldsState };
                userFields.fields[val.id] = data;
                handleOnUpdateUserFields(userFields);
              };
              const handlerInputFile = (files) => {
                const userFields = { ...userFieldsState };
                userFields[`files[${val.id}]`] = files;
                handleOnUpdateUserFields(userFields);
              };

              const resetSelectItem = () => {
                handleOnUpdateUserFields({ id: 0, fields: {} });
              };

              let value = userFieldsState.fields[val.id] || '';
              return (
                <div key={userFieldIndex} className={styles.userFieldContainer}>
                  <WorkRightAddMenuItem
                    item={{
                      type: val.type.type_name,
                      name: val.name,
                      setting: JSON.parse(val.setting),
                      id: val.id,
                    }}
                    selectItem={value}
                    resetSelectItem={resetSelectItem}
                    setWidget={{}}
                    setNewSelectItem={handlerInputData}
                    setFiles={handlerInputFile}
                    isUserFieldsOperation={true}
                    disabled={!editMode}
                  />
                  {isFormValidate ? (
                    val.required &&
                    userFieldsState.fields[val.id].value === '' &&
                    (!userFieldsState.fields[val.id].files ||
                      userFieldsState.fields[val.id].files.length === 0) &&
                    isFormValidate && (
                      <div className={styles.dataErrorBlock + ' ' + styles.dataErrorBlockDinamyc}>
                        <img src={attentionIcon} alt={'error'} />
                        <div className={styles.dataError}>Поле обязательно для заполнения</div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceItem;
