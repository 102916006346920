import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const ListDrawerContext = createContext(null);

export const useListDrawerContext = () => {
  const context = useContext(ListDrawerContext);
  if (!context) {
    throw new Error('useListDrawerContext must be used within a TableProvider');
  }
  return context;
};

export const ListDrawerProvider = ({
  children,
  actionsCallbacks = [],
  groups = undefined,
  items = undefined,
  loadGroupApi = undefined,
  resetGroup = undefined,
  editGroupCallback = undefined,
  deleteGroupCallback = undefined,
  onUpdate = undefined,
  selectedValues = ['id'],
  selectedKey = 'id',
  onSelect = undefined,
}) => {
  const [actions, setActions] = useState(actionsCallbacks); // { name: "", callback: () => void }
  const [selected, setSelected] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [search, setSearch] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => setIsFirstRender(false), []);

  useEffect(() => {
    update({ search: search ? search : undefined });
  }, [search]);

  useEffect(() => {
    if (onSelect) onSelect(selected);
  }, [selected]);

  const update = useCallback(
    ({ search = undefined }) => {
      if (onUpdate) {
        onUpdate(expandedGroups, { search });
      }
    },
    [expandedGroups],
  );

  const value = useMemo(
    () => ({
      actions,
      groups,
      items,
      selected,
      setSelected,
      expandedGroups,
      setExpandedGroups,
      loadGroupApi,
      resetGroup,
      editGroupCallback,
      deleteGroupCallback,
      selectedKey,
      selectedValues,
      search,
      update,
      setSearch,
    }),
    [groups, items, selected, expandedGroups],
  );

  return <ListDrawerContext.Provider value={value}>{children}</ListDrawerContext.Provider>;
};
