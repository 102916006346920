import styles from './ListNavigation.module.css';
import { useEffect, useMemo, useRef } from 'react';
import { useListDrawerContext } from '../ListDrawerProvider';
import ListDrawerToolbar from '../ListDrawerToolbar';
import ListGroupItem from './ListGroupItem';
import ListItem from './ListItem';

const ListNavigation = () => {
  const { groups, items } = useListDrawerContext();

  const columnsSettingsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (columnsSettingsRef.current && !columnsSettingsRef.current.contains(event.target)) {
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const groupsMemo = useMemo(
    (() => {
      return groups?.map((group, index) => (
        <ListGroupItem key={index} group={group} />
      ));
    }),
    [groups],
  );

  const itemsMemo = useMemo(
    () =>
      items?.map((item, index) => (
        <ListItem key={index} item={item} />
      )),
    [groups],
  );

  return (
    <div className={styles.container}>
      <ListDrawerToolbar />
      <table className={styles.table}>
        <tbody>
          {groupsMemo}
          {itemsMemo}
        </tbody>
      </table>
    </div>
  );
};

export default ListNavigation;
