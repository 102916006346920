import styles from './ListNavigationDrawer.module.css';
import { forwardRef, useImperativeHandle } from 'react';
import { ListDrawerProvider, useListDrawerContext } from './ListDrawerProvider';
import ListNavigation from './ListNavigation';
import DrawerContent from './DrawerContent';

const ListNavigationDrawer = forwardRef(({ children, ...rest }, ref) => {
  return (
    <ListDrawerProvider {...rest}>
      <ListDrawerComponent ref={ref}>{children}</ListDrawerComponent>
    </ListDrawerProvider>
  );
});

const ListDrawerComponent = forwardRef(({ children = null }, ref) => {
  const {
    setSelected
  } = useListDrawerContext();

  useImperativeHandle(ref, () => ({
    resetSelected: () => setSelected(null),
  }));

  return (
    <div className={styles.container}>
      <ListNavigation />
      <DrawerContent>{children}</DrawerContent>
    </div>
  );
});

ListDrawerComponent.displayName = 'ListDrawerComponent';
ListNavigationDrawer.displayName = 'ListNavigationDrawer';

export default ListNavigationDrawer;
