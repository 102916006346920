import { address_server_short, API_V2 } from '../config';
import { error } from '../utils/handlerError';
import { mode } from 'mathjs';
import { axiosInstance } from './common/axios.instance';

const DEVICES = 'devices';
const DEVICE_MODELS = 'device-models';

export async function getAllDevices({ page, search, sorting, columns } = {}) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICES}`, {
    withCredentials: true,
    params: {
      page,
      search,
      sorting: sorting?.field && sorting?.type ? JSON.stringify(sorting) : undefined,
      columns: columns?.length ? JSON.stringify(columns) : columns ? JSON.stringify([]) : undefined,
    },
  });

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getAllDevices', data);
}

export async function getAllDeviceModels({ search, sorting } = {}) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICE_MODELS}`, {
    withCredentials: true,
    params: {
      search,
      sorting: sorting?.field && sorting?.type ? JSON.stringify(sorting) : undefined,
    }
  });

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getAllDevices', data);
}

export async function createDevice(device) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICES}`, {
    withCredentials: true,
    method: 'POST',
    data: {
      name: device.name,
      id_model: device.id_model,
      inventory_number: device.inventory_number,
      description: device.description,
    },
  });

  if (!data.success) {
    error('createDevice', data);
  }

  return data;
}

export async function updateDevice(device) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICES}/${device.id}`, {
    withCredentials: true,
    method: 'PUT',
    data: {
      name: device.name,
      id_model: device.id_model,
      inventory_number: device.inventory_number,
      description: device.description,
    },
  });

  if (!data.success) {
    error('updateDevice', data);
  }

  return data;
}

export async function deleteDevice(id) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICES}/${id}`, {
    withCredentials: true,
    method: 'DELETE',
  });

  if (!data.success) {
    error('deleteDevice', data);
  }

  return data;
}

export async function createDeviceModel(model) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICE_MODELS}`, {
    withCredentials: true,
    method: 'POST',
    data: {
      ...model,
    },
  });

  if (!data.success) {
    error('createDeviceModel', data);
  }

  return data;
}

export async function deleteDeviceModel(id) {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICE_MODELS}/${id}`, {
    withCredentials: true,
    method: 'DELETE',
  });

  if (!data.success) {
    error('deleteDeviceModel', data);
  }

  return data;
}

export async function getDeviceModelsIcons() {
  const { data } = await axiosInstance(`${address_server_short}${API_V2}/${DEVICE_MODELS}/icons`, {
    withCredentials: true,
  });

  if (data.success) {
    if (data.result) return data.result;
    return [];
  }
  error('getDeviceModelsIcons', data);
}
