import { autorun, makeAutoObservable, runInAction } from 'mobx';
import {
  getAllProtocolGroups,
  getAllProtocolsByGroupId,
  updateProtocolGroup,
  createProtocolGroup,
  deleteProtocolGroup,
  createProtocol,
  getProtocolById,
  updateProtocol,
  deleteProtocol,
} from 'services/protocol.services';
import { millisecondsToTime, stringSorting, timeToMilliseconds } from 'utils/transform';

const NAME = 'protocols-store';
export const GroupOperations = Object.freeze({
  Add: 'add',
  Edit: 'edit',
});

export const ProtocolOperations = Object.freeze({
  Add: 'add',
  Edit: 'edit',
  View: 'view',
});

export class ProtocolsStore {
  groups = [];

  protocolOperationMode = null;
  operationMode = null;
  createGroupState = null;
  updateGroupState = null;

  selectedProtocolId = null;
  protocolState = null;

  constructor() {
    makeAutoObservable(this);

    const storeJSON = localStorage.getItem(NAME);

    if (storeJSON) Object.assign(this, JSON.parse(storeJSON));

    autorun(() => {
      const obj = JSON.parse(JSON.stringify(this));
      localStorage.setItem(NAME, JSON.stringify(obj));
    });

    window.addEventListener('storage', this._updateStore);
  }

  _updateStore = (e) => {
    if (e.key === NAME) {
      const storeJSON = localStorage.getItem(NAME);

      if (storeJSON) Object.assign(this, JSON.parse(storeJSON));
    }
  };

  _dispose = () => {
    window.removeEventListener('storage', this._updateStore);
  };

  _createProtocol = async () => {
    const response = await createProtocol(this._prepareProtocolForApiRequest());
    return response;
  };

  _updateProtocol = async () => {
    const response = await updateProtocol(this._prepareProtocolForApiRequest());
    return response;
  };

  _prepareProtocolForApiRequest = () => {
    return {
      ...this.protocolState,
      operations: this.protocolState.operations.map((operation, index) => ({
        text: operation.text,
        time: timeToMilliseconds(operation.time?.value),
        order: index,
        device_models: operation.devices?.map((device) => ({
          id: device.id,
          user_fields: device.user_fields?.map((field) => ({ id: field.id, value: field.value })),
        })),
      })),
    };
  };

  _setDefaultGroupsAtStartOfArray = (array) => {
    const defaultGroups = [];
    array = array.filter((group) => {
      if (!group.is_editable && !group.is_deletable) {
        defaultGroups.push(group);
        return false;
      }
      return true;
    });
    return [...defaultGroups, ...array];
  };

  setGroups = async () => {
    const response = await getAllProtocolGroups({
      sorting: { field: 'name', type: 'asc' },
    });

    runInAction(() => {
      this.groups = this._setDefaultGroupsAtStartOfArray(response.data);
    });
  };

  setGroupsAndProtocols = async (expandedGroups, { search = undefined } = {}) => {
    const response = await getAllProtocolGroups({
      search,
      sorting: { field: 'name', type: 'asc' },
    });

    const groupsWithItems = await Promise.all(
      response.data.map(async (group) => {
        if (expandedGroups.includes(group.id)) {
          const items = await getAllProtocolsByGroupId(group.id);
          return { ...group, items: items.data };
        }
        return { ...group };
      }),
    );

    runInAction(() => {
      this.groups = this._setDefaultGroupsAtStartOfArray(groupsWithItems);
    });
  };

  setUpdateGroupState = (group) => {
    runInAction(() => (this.updateGroupState = group));
  };

  setCreateGroupState = (group) => {
    runInAction(() => (this.createGroupState = group));
  };

  setOperationMode = (mode) => {
    if (Object.values(GroupOperations).includes(mode) || mode === null)
      runInAction(() => (this.operationMode = mode));
  };

  setProtocolOperationMode = (mode) => {
    if (Object.values(ProtocolOperations).includes(mode) || mode === null)
      runInAction(() => (this.protocolOperationMode = mode));
  };

  selectedGroup = () => {
    return this.operationMode === GroupOperations.Add
      ? this.createGroupState
      : this.updateGroupState;
  };

  updateSelectedGroup = (object) => {
    return runInAction(() =>
      this.operationMode === GroupOperations.Add
        ? (this.createGroupState = object)
        : (this.updateGroupState = object),
    );
  };

  upsertGroup = async () => {
    let res;
    if (this.operationMode === GroupOperations.Edit) {
      res = await updateProtocolGroup({ ...this.selectedGroup() });
      if (res.success) {
        const updatedGroup = res.result.data;
        runInAction(() => {
          let updatedGroups = stringSorting(
            this.groups.reduce((acc, current, index) => {
              if (current.id === updatedGroup.id)
                current = {
                  ...res.result.data,
                  items: this.groups[index].items,
                };
              acc.push(current);
              return acc;
            }, []),
            'name',
          );
          this.groups = this._setDefaultGroupsAtStartOfArray(updatedGroups);
        });
      }
    }
    if (this.operationMode === GroupOperations.Add) {
      res = await createProtocolGroup({ ...this.selectedGroup() });
      if (res.success) {
        runInAction(() => {
          let updatedGroups = stringSorting([...this.groups, res.result.data], 'name');
          this.groups = this._setDefaultGroupsAtStartOfArray(updatedGroups);
        });
      }
    }

    return res;
  };

  removeGroup = async (id) => {
    const res = await deleteProtocolGroup(id);

    if (res.success) {
      runInAction(() => {
        let updatedGroups = this.groups.filter((group) => group.id != id);
        this.groups = this._setDefaultGroupsAtStartOfArray(updatedGroups);
      });
    }
    return res;
  };

  setProtocolsByGroupId = async (id) => {
    const targetGroupIndex = this.groups.findIndex((group) => group.id === id);
    if (targetGroupIndex !== -1) {
      const res = await getAllProtocolsByGroupId(id);
      runInAction(() => {
        this.groups[targetGroupIndex].items = res.data;
      });
    }
  };

  resetGroupProtocols = async (id) => {
    const targetGroupIndex = this.groups.findIndex((group) => group.id === id);
    runInAction(() => {
      this.groups[targetGroupIndex].items = undefined;
    });
  };

  selectedProtocol = () => {
    return this.protocolState;
  };

  updateSelectedProtocol = (object) => {
    return runInAction(() => (this.protocolState = object));
  };

  upsertProtocol = async () => {
    let response;
    if (this.selectedProtocol().id) response = await this._updateProtocol();
    else response = await this._createProtocol();

    if (!response.result) return response;

    const groupIndex = this.groups.findIndex((group) => group.id === response.result.data.id_group);
    if (groupIndex != -1 && this.groups[groupIndex].items) {
      runInAction(() => {
        let updatedGroups = stringSorting(
          this.groups.reduce((acc, current, index) => {
            if (current.id === response.result.data.id_group)
              current = {
                ...current,
                items: this.protocolState.id
                  ? current.items.map((item) =>
                      item.id === response.result.data.id ? response.result.data : item,
                    )
                  : [...this.groups[index].items, { ...response.result.data }],
              };
            acc.push(current);
            return acc;
          }, []),
          'name',
        );
        this.groups = this._setDefaultGroupsAtStartOfArray(updatedGroups);
      });
    }

    return response;
  };

  removeProtocol = async () => {
    const res = await deleteProtocol(this.protocolState.id);

    if (res.success) {
      runInAction(() => {
        this.groups = this.groups?.map((group) => {
          if (group.id == this.protocolState.id_group) {
            return {
              ...group,
              items: group.items.filter((protocol) => protocol.id !== this.protocolState.id),
            };
          } else {
            return group;
          }
        });
      });
    }

    return res;
  };

  getByIdProtocol = async (id) => {
    const response = await getProtocolById(id);
    return {
      ...response?.data,
      operations: response?.data.operations.map((operation) => ({
        text: operation.text,
        time: {
          value: millisecondsToTime(operation.time),
          setting: {
            date: millisecondsToTime(operation.time),
          },
        },
        order: operation.order,
        devices: operation.device_models?.map((device) => ({
          id: device.id,
          name: device.name,
          image: device.image,
          user_fields: device.user_fields,
        })),
      })),
    };
  };

  setSelected = (id) => {
    runInAction(() => (this.selectedProtocolId = id));
  };

  updateOperation = (index, operation) => {
    runInAction(() => {
      this.protocolState.operations[index] = operation;
    });
  };

  resetProtocol = () =>
    runInAction(() => {
      this.protocolState = null;
      this.selectedProtocolId = null;
      this.protocolOperationMode = null;
    });
}

export const protocolsStore = new ProtocolsStore();
