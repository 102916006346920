import InputMask from 'react-input-mask';
import classes from './WidgetPhone.module.css';

const WidgetPhone = ({ value, onChange, disabled = false }) => {
  return (
    <InputMask
      mask="+99999999999"
      className={classes.input}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      disabled={disabled}
    />
  );
};

export default WidgetPhone;
