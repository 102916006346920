import React, { useContext, useEffect, useState } from 'react';
import WorkLeftHeader from '../WorkLeftHeader/workLeftHeader';
import WorkLeftManual from '../WorkLeftManual/workLeftManual';
import WorkLeftUsers from '../WorkLeftUsers/workLeftUsers';
import { getReportsGroups } from '../../../services/report.services';
import { getStoragePacks, getStorages } from '../../../services/storage.services';
import { getGroups } from '../../../services/user.services';
import WorkLeftReports from '../WorkLeftReports/workLeftReports';
import WorkLeftStorage from '../WorkLeftStorage/workLeftStorage';
import { modeContext } from '../../Contexts/contexts';
import { StructureStore } from '../../../store/StructureStore';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import classes from './workLeft.module.css';
import { getFreePacks } from '../../../services/pucks.services';
import { Store } from '../../../store/Store';
import ProtocolsTabPage from 'components/Tabs/ProtocolsTabPage';

const WorkLeft = observer(({ sizeX, mode }) => {
  const store = useContext(Store);
  const structureStore = useContext(StructureStore);
  const { widget } = useContext(modeContext);

  const [filter, setFilter] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [data, setLeftData] = useState([]);
  const [visible, setVisible] = useState(1);

  useEffect(() => {
    if (sizeX === 0) setVisible(0);
    else setVisible(1);
  }, [sizeX]);

  useEffect(() => {
    if (data.length > 0 && mode.type?.item === '' && mode.path !== 'Хранилище') {
      mode.setType({ mode: 'view', item: data[0] });
    }
  }, [mode]);

  useEffect(() => {
    getGroupsT();
  }, [mode.type, filter, favorite, mode.puckSelectModal, mode.move]);

  const getGroupsT = async () => {
    if (mode.path === 'Пользователи') {
      let res = await getGroups();
      if (filter !== '')
        res = res.filter((item) => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
      setLeftData(res);
      if (res.length > 0) if (!mode.type.item) mode.setType({ mode: 'view', item: res[0] });
    }
    if (mode.path === 'Отчеты') {
      let res = await getReportsGroups();
      if (filter !== '')
        res = res.filter((item) => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
      setLeftData(res);
      if (res.length > 0) if (!mode.type.item) mode.setType({ mode: 'view', item: res[0] });
    }
    if (mode.path === 'Справочники') {
      structureStore.setFullCatalog();
      structureStore.setCatalog(filter);
      setLeftData(structureStore.catalog);
    }
    if (mode.path === 'Хранилище') {
      await store.loadTree(favorite, filter);
    }
  };
  return (
    <div className={classes.WorkLeft} style={{ width: sizeX + 'px', opacity: visible }}>
      <WorkLeftHeader
        mode={mode}
        filter={filter}
        setFilter={setFilter}
        favorite={favorite}
        setFavorite={setFavorite}
      />
      {mode.path === 'Хранилище' && (
        <WorkLeftStorage
          setWidget={widget.setWidget}
          favorite={favorite}
          filter={filter}
          mode={mode}
        />
      )}
      {mode.path === 'Отчеты' && (
        <WorkLeftReports key={uuid()} setWidget={widget.setWidget} reports={data} mode={mode} />
      )}
      {mode.path === 'Справочники' && (
        <WorkLeftManual key={uuid()} setWidget={widget.setWidget} manuals={data} mode={mode} />
      )}
      {mode.path === 'Пользователи' && (
        <WorkLeftUsers key={uuid()} setWidget={widget.setWidget} userGroup={data} mode={mode} />
      )}
      {mode.path === 'Протоколы' && (
        <ProtocolsTabPage mode={mode} />
      )}
    </div>
  );
});

export default WorkLeft;
