import styles from './ListNavigation.module.css';
import { useListDrawerContext } from '../ListDrawerProvider';
import ActionButton from 'ui/ActionButton';
import editIcon from 'assets/svg/edit-01.svg';
import deleteIcon from 'assets/svg/delete-01.svg';
import classNames from 'classnames';
import Icon from 'helpers/components/Icon';
import arrowIcon from 'assets/svg/arrow-01.svg';
import { useEffect, useState } from 'react';
import { COLORS } from 'config.js';

const ListItem = ({ item, cellClassName = undefined }) => {
  const {
    selected,
    selectedKey,
    setSelected
  } = useListDrawerContext();

  const [expandGroup, setExpandGroup] = useState(false);
  const [isSelectedRow, setIsSelectedRow] = useState(false);

  useEffect(() => {
    setIsSelectedRow(selected === item[selectedKey]);
  }, [selected]);

  return (
    <tr className={classNames(styles.rowItem, { [styles.active]: isSelectedRow })} onClick={() => setSelected(item[selectedKey])}>
      <td colSpan={2} className={classNames(styles.item, styles.defaultItem, cellClassName)}>
        <div className={styles.itemName}>
        {item.name}
        </div>
        <div className={classNames({ [styles.underline]: item.is_editable || item.is_deletable })}></div>
      </td>
    </tr>
  );
};

export default ListItem;
