import styles from './ProtocolsTabPage.module.css';

import useAlertWidget from 'hooks/useAlertWidget';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GroupOperations, ProtocolOperations, protocolsStore } from 'store/protocols.store';
import ListNavigationDrawer from 'ui/ListNavigationDrawer';
import UpsertGroupModal from './UpsertGroupModal';
import UpsertProtocol from './UpsertProtocol';

const ProtocolsTabPage = observer(({ mode }) => {
  const { setWidget } = useAlertWidget();
  const {
    groups,
    setGroups,
    resetGroupProtocols,
    setGroupsAndProtocols,
    setUpdateGroupState,
    setCreateGroupState,
    setOperationMode,
    removeGroup,
    setProtocolsByGroupId,
    updateSelectedProtocol,
    setProtocolOperationMode,
    protocolOperationMode,
    selectedProtocolId,
    setSelected,
  } = protocolsStore;

  const listNavigationRef = useRef(null);
  const [groupModalOpened, setGroupModalOpened] = useState(false);
  const [upsertProtocolOpened, setUpsertProtocolOpened] = useState(false);

  const actions = [
    {
      name: 'Новую группу',
      callback: () => createGroup(),
    },
    {
      name: 'Новый протокол',
      callback: () => createProtocol(),
    },
  ];

  const loadGroups = async () => {
    await setGroups();
  };

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    setUpsertProtocolOpened(!!selectedProtocolId);
  }, [selectedProtocolId]);

  const createProtocol = useCallback(async () => {
    updateSelectedProtocol({
      id_group: '',
      name: '',
      operations: [],
    });
    setProtocolOperationMode(ProtocolOperations.Add);
    listNavigationRef.current.resetSelected();
  }, []);

  const editGroup = useCallback(async (value) => {
    setOperationMode(GroupOperations.Edit);
    setUpdateGroupState(value);
    setGroupModalOpened(true);
  }, []);

  const createGroup = useCallback(async () => {
    setOperationMode(GroupOperations.Add);
    setCreateGroupState(null);
    setGroupModalOpened(true);
  }, []);

  const deleteGroup = useCallback((value) => {
    setWidget({
      status: 'Sure',
      text: 'Вы действительно хотите удалить группу?',
      fun: async () => {
        const result = await removeGroup(value.id);

        if (result.error_code == 2008) return alarmGroupContainsRelated();
        if (result.error_code) return errorCommon(result.error_code);

        return successGroupDeleted();
      },
    });
  }, []);

  const refreshGroupsAndProtocols = useCallback((expandedGroups, { search = undefined } = {}) => {
    setGroupsAndProtocols(expandedGroups, { search });
  }, []);

  const openAnotherProtocol = (id) => setSelected(id);

  const handleSelectProtocol = async (id) => {
    if (
      (selectedProtocolId && protocolOperationMode == ProtocolOperations.Edit) ||
      (!selectedProtocolId && protocolOperationMode == ProtocolOperations.Add)
    ) {
      return alarmUnsavedDataExists(() => openAnotherProtocol(id));
    }

    openAnotherProtocol(id);
  };

  const alarmUnsavedDataExists = (callback) =>
    setWidget({
      status: 'Sure',
      text: 'Вы действительно хотите открыть другой протокол? Все несохранненные данные будут удалены.',
      fun: () => callback(),
    });
  const alarmGroupContainsRelated = () =>
    setWidget({
      status: 'Alarm',
      text: 'Группа содержит протоколы!',
      fun: () => {},
    });
  const errorCommon = (code) =>
    setWidget({
      status: 'Error',
      text: `Код № ${code}`,
      fun: () => {},
    });
  const successGroupDeleted = () =>
    setWidget({
      status: 'Ok',
      text: `Группа успешно удалена!`,
      fun: () => {},
    });

  return (
    <div className={styles.pageContainer}>
      <ListNavigationDrawer
        ref={listNavigationRef}
        actionsCallbacks={actions}
        groups={groups}
        loadGroupApi={setProtocolsByGroupId}
        resetGroup={resetGroupProtocols}
        editGroupCallback={editGroup}
        deleteGroupCallback={deleteGroup}
        onUpdate={refreshGroupsAndProtocols}
        onSelect={handleSelectProtocol}
      >
        {(upsertProtocolOpened || protocolOperationMode === ProtocolOperations.Add) && (
          <UpsertProtocol />
        )}
      </ListNavigationDrawer>
      {groupModalOpened && <UpsertGroupModal setModalOpened={setGroupModalOpened} mode={mode} />}
    </div>
  );
});

export default ProtocolsTabPage;
