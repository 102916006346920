import styles from './ListDrawerToolbar.module.css';
import searchIcon from '../../../assets/svg/serch-01.svg';
import settingsIcon from '../../../assets/svg/settings-01.svg';
import { useEffect, useRef, useState } from 'react';
import Icon from 'helpers/components/Icon';
import Tooltip from 'ui/Tooltip';
import DropdownComponent from 'components/Widgets/Dropdown/DropdownComponent';
import confirmIcon from '../../../assets/svg/button_on-01.svg';
import { useListDrawerContext } from '../ListDrawerProvider';
import arrowIcon from 'assets/svg/arrow-01.svg';
import classNames from 'classnames';
import { COLORS } from 'config.js';

const ListDrawerToolbar = () => {
  const { actions, update, search, setSearch } = useListDrawerContext();

  const [searchValue, setSearchValue] = useState('');
  const [openAddingMenu, setOpenAddingMenu] = useState(false);

  const addingMenuRef = useRef(null);

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  const handleClickOutside = (event) => {
    if (addingMenuRef.current && !addingMenuRef.current.contains(event.target)) {
      setOpenAddingMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.toolBar}>
      <div className={styles.defaultActions}>
        <div className={styles.searchInputContainer}>
          <img className={styles.searchIcon} src={searchIcon} alt={'search'} />
          <input
            className={styles.searchInput}
            placeholder={'Отобрать'}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={styles.addingMenu} ref={addingMenuRef}>
          <button
            className={classNames(styles.addButton)}
            onClick={() => {
              setOpenAddingMenu(!openAddingMenu);
            }}
          >
            <span>Добавить</span>
            <Icon
              className={classNames(styles.addIcon, { [styles.openedAddingMenu]: openAddingMenu })}
              color={'white'}
              src={arrowIcon}
              width={'14px'}
              height={'14px'}
            />
          </button>
          <div
            className={styles.addingMenuContainer}
            style={{ display: openAddingMenu ? 'block' : 'none' }}
          >
            {actions.map((item, index) => (
              <div
                key={index}
                className={styles.addingMenuItem}
                onClick={() => {
                  setOpenAddingMenu(!openAddingMenu);
                  item.callback();
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles.refreshBlock}
          onClick={() => {
            update({ search: search ? search : undefined });
          }}
        >
          <div className={styles.refresh}></div>
          <div className={styles.tooltip}>Обновить</div>
        </div>
      </div>
    </div>
  );
};

export default ListDrawerToolbar;
