export const userFieldCategory = {
  device: 'device',
  sample: 'sample',
};

export const allowedFieldTypes = {
  [userFieldCategory.device]: [
    'integer',
    'float',
    'range',
    'date',
    'time',
    'string',
    'text',
    'select',
    'select_check',
    'switch_variants',
    'switch',
    'yes/no',
  ],
};