import { modeContext } from 'components/Contexts/contexts';
import React from 'react';

const useAlertWidget = () => {
  const mainContext = React.useContext(modeContext);
  const { setWidget } = mainContext.widget;

  return {
    setWidget,
  };
};

export default useAlertWidget;
