import { createContext, useContext, useMemo, useState } from 'react';

const UpsertProtocolContext = createContext(null);

export const useUpsertProtocolContext = () => {
  const context = useContext(UpsertProtocolContext);
  if (!context) {
    throw new Error('useUpsertProtocolContext must be used within a UpsertProtocolProvider');
  }
  return context;
};
export const UpsertProtocolProvider = ({ children }) => {
  const [operations, setOperations] = useState([]);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const contextValue = useMemo(
    () => ({
      operations,
      isFormValidate,
      setOperations,
      editMode,
      setEditMode,
      setIsFormValidate,
      name,
      selectedGroup,
      setSelectedGroup,
      setName,
    }),
    [operations, isFormValidate, editMode, name, selectedGroup],
  );

  return (
    <UpsertProtocolContext.Provider value={contextValue}>{children}</UpsertProtocolContext.Provider>
  );
};
