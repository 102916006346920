import { observer } from 'mobx-react-lite';
import styles from './devicesUpsertTabPage.module.css';
import { useEffect } from 'react';
import { DeviceOperations, devicesStore } from 'store/devices.store';
import React from 'react';
import classNames from 'classnames';
import AddDeviceModelModal from './AddDeviceModelModal';
import DropdownComponent from 'components/Widgets/Dropdown/DropdownComponent';
import Icon from 'helpers/components/Icon';
import addIcon from 'assets/svg/add-01.svg';
import { modeContext } from 'components/Contexts/contexts';
import TextField from 'components/Widgets/TextField';
import attentionIcon from 'assets/svg/attention-01.svg';

const DevicesUpsertTabPage = observer(({ mode }) => {
  const mainContext = React.useContext(modeContext);
  const { setWidget } = mainContext.widget;
  const {
    setDevicesModels,
    deviceModels,
    upsertDevice,
    removeDeviceModel,
    setOperationMode,
    selectedDevice,
    updateSelectedDevice

  } = devicesStore;

  const [device, setDevice] = React.useState(null);

  const [isFormValidate, setIsFormValidate] = React.useState(false);
  const [addDeviceModelModal, setAddDeviceModelModal] = React.useState(false);
  const [selectedDeviceModel, setSelectedDeviceModel] = React.useState(null);

  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [inventoryNumber, setInventoryNumber] = React.useState('');

  useEffect(() => {
    setDevicesModels();
  }, [])

  useEffect(() => {
    const activeTab = mode.tabs.find((tab) => tab.id === mode.activeTabId);
    setOperationMode(activeTab?.operation);
    setDevice(selectedDevice());
    setIsFormValidate(false);
  }, [mode.activeTabId])

  useEffect(() => {
    setName(device?.name ?? '');
    setDescription(device?.description ?? '');
    setInventoryNumber(device?.inventory_number ?? '');
    setSelectedDeviceModel(device?.id_model ?? null);
  }, [device])

  useEffect(() => {
    updateSelectedDevice({
      id: device?.id,
      name: name,
      description: description,
      inventory_number: inventoryNumber,
      id_model: selectedDeviceModel,
    });
  }, [name, description, inventoryNumber, selectedDeviceModel])

  const cancelClick = () => {
    closeTab();
  };

  const closeTab = () => {
    updateSelectedDevice(null);
    let tabs = JSON.parse(JSON.stringify(mode.tabs));
    let activeTab = tabs.find((val) => val.id == mode.activeTabId);
    let index = tabs.indexOf(activeTab);
    tabs.splice(index, 1);

    mode.setTabs(tabs);
    if (tabs.length > 0) {
      mode.setActiveTabId(tabs[tabs.length - 1].id);
      mode.setModeByParseTab(tabs[tabs.length - 1]);
    } else {
      mode.setPath('');
      mode.setType({ mode: 'view', item: '' });
      mode.setActiveTabId(0);
    }
  };

  const validateForm = () => {
    let validationResult = false;
    setIsFormValidate(true);
    const model = selectedDevice();
    if (!model?.name || !model?.description || !selectedDeviceModel || !model?.inventory_number) return false;
    if (device?.id)
      setWidget({
        status: 'Attention',
        text: `Данные типа источника будут изменены`,
        fun: async () => { submitClick() },
      });
    else submitClick();
  }

  const submitClick = async () => {
    const result = await upsertDevice();

    if (!result.success) {
      errorHandler(result.error_code, result.error);
    } else {
      setWidget({
        status: 'Ok',
        text:
          selectedDevice()?.id != null
            ? `Оборудование успешно изменено!`
            : 'Оборудование добавлено успешно!',
        fun: async () => {
          closeTab();
        },
      });
    }
  };

  const removeModelClick = async (id) => {
    const result = await removeDeviceModel(id);

    if (!result.success) {
      errorHandler(result.error_code);
    }
  }

  const errorHandler = (code, message) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}${message ? ` - ${message}`: ''}.`,
      fun: async () => { },
    });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.title}>Стандартные поля</div>
      <TextField
        className={styles.formField}
        label={'Наименование'}
        field={name}
        onChange={setName}
        required
        isFormValidate={isFormValidate}
      ></TextField>
      <TextField
        className={styles.formField}
        label={'Инвентарный номер'}
        field={inventoryNumber}
        onChange={setInventoryNumber}
        required
        isFormValidate={isFormValidate}
      ></TextField>
      <TextField
        className={styles.formField}
        label={'Описание'}
        field={description}
        onChange={setDescription}
        required
        isFormValidate={isFormValidate}
      ></TextField>
      <div className={styles.title}>Модель</div>
      <div
        className={styles.formField}>
        <div className={styles.modelSelectContainer}>
          <div className={styles.formFieldContainer}>
            <div className={styles.label}>Выберите модель</div>
            <DropdownComponent
              items={deviceModels}
              keyItem={'id'}
              valueItem={'name'}
              selectedKey={selectedDeviceModel}
              isDelete={true}
              onDeleteClick={removeModelClick}
              deleteMessageConfirm={'Вы уверены что хотите удалить данную модель?'}
              onChange={(value) => setSelectedDeviceModel(value)}
            />
          </div>
          <div
            className={styles.addNewModelContainer}
            onClick={() => setAddDeviceModelModal(true)}
          >
            <div className={styles.addButtonContainer}>
              <Icon className={styles.addButton} color={'#fff'} src={addIcon} />
            </div>
            <span>Создать новую модель</span>
          </div>
        </div>
        {!selectedDeviceModel && isFormValidate && (
          <div className={styles.dataErrorBlock}>
            <img src={attentionIcon} alt={'attention'} />
            <div className={styles.dataError}>Поле обязательно для заполнения</div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <button className={classNames('button_default', 'save_style')} onClick={validateForm}>
          {device?.id == null ? 'Добавить' : 'Редактировать'}
        </button>
        <button className={classNames('button_default', 'cancel_style')} onClick={() => cancelClick()}>
          Отмена
        </button>
      </div>
      {addDeviceModelModal && (
        <AddDeviceModelModal
          setModalOpened={setAddDeviceModelModal}
          mode={mode}
        />
      )}
    </div>
  );
});

export default DevicesUpsertTabPage;