import classes from './WidgetSwitchVariants.module.css';

const WidgetSwitchVariants = ({
  item,
  current,
  onChange,
  itemKey = 'value',
  itemValue = 'param',
  disabled = false
}) => {
  return (
    <div className={classes.radio_group}>
      {item.setting?.map((element, i) => {
        const id = element[itemKey];

        return (
          <div className={classes.form_radio} key={`${i}_${item.id}`}>
            <input
              type="radio"
              name={`${i}_${item.id}`}
              id={`${i}_${item.id}`}
              checked={current ? element[itemKey] === current[itemKey] : false}
              onChange={() => onChange(element)}
              disabled={disabled}
            />
            <label htmlFor={`${i}_${item.id}`}>{element[itemValue]}</label>
          </div>
        );
      })}
    </div>
  );
};

export default WidgetSwitchVariants;
